<template>
	<body id="app" class="vcc-body" v-hotkey="keymap">
		<vcc-header v-if="!signUpFormShowed"/>
			<router-view/>
		<vcc-footer v-if="!signUpFormShowed" />
	</body>

</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import AuthStatus from '@/helpers/auth.js'
import { mapGetters, mapActions } from 'vuex'
export default {
	components: {
		'vcc-header': Header,
		'vcc-footer': Footer,
	},
	computed: {
		...mapGetters(['isShowSingUpForm']),
		keymap() {
			return {
				'l+ctrl+shift': this.login,
				'r+ctrl+shift': this.reports,
			}
		},
		signUpFormShowed() {
			return this.$route.name == 'SubscribeToWebinar' && window.visualViewport.width <= 600 ? true : false
		}
	},
	methods: {
		...mapActions(['fetchAllBusinessTypes', 'checkAuthStatus']),
		login() {this.$router.push({ name: 'Login' })},
		reports() {
			if(AuthStatus.getRole() === '1') this.$router.push({ name: 'AdminPage' })
			if(AuthStatus.getRole() === '2') this.$router.push({ name: 'OrganizationRegistry' })
		}
	},
	created() {
		this.fetchAllBusinessTypes()
		this.checkAuthStatus()
	}
}
</script>

<style>
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

/*
#nav {
	padding: 30px;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
} */
</style>
