<template>
	<form @submit.prevent="sendEmail(type)" class="subscribe-form__header" v-if="type === 'popup'">
		<button type="reset" @click="closeMe" aria-label="Закрыть форму" class="btn__close"></button>
		<fieldset v-if="delivered === 'idle'">
			<legend>Получать уведомления об обновлениях на почту</legend>
			<input v-model="user_data.email" type="email" name="email" id="email" class="input input__white input__white-bordered input__slim" placeholder="Ваша электронная почта..." required>
			<button type="submit" class="btn__white">Отправить</button>
		</fieldset>
		<article v-else-if="delivered === 'conflict'" class="sf--message sf--message__conflict">
			<strong>Не получилось...</strong>
			<p>Вы уже подписаны на этот раздел Базы знаний</p>
		</article>
		<article v-else-if="delivered === 'success'" class="sf--message sf--message__success">
			<strong>Получилось!</strong>
			<p>Теперь вы будете получать уведомления об обновлениях консультационного центрана свой почтовый ящик.</p>
		</article>
		<article v-else-if="delivered === 'fail'" class="sf--message sf--message__fail">
			<strong>Не получилось...</strong>
			<p>Что-то пошло не так, и мы уже работаем над этим. Попробуйте еще раз через какое-то время.</p>
		</article>
	</form>
	<form @submit.prevent="sendEmail(type)" class="subscribe-form__header" v-else>
		<fieldset v-if="delivered === 'idle'">
			<legend>Последнее обновление: {{ lastUpdate }}</legend>
			<input v-model="user_data.email" type="email" name="email" id="email" class="input input__white input__white-bordered input__slim" placeholder="Ваша электронная почта..." required>
			<button type="submit" class="btn__white">Получать уведомления на почту</button>
		</fieldset>
		<article v-else-if="delivered === 'conflict'" class="sf--message sf--message__conflict">
			<strong>Не получилось...</strong>
			<p>Вы уже подписаны на этот раздел Базы знаний</p>
		</article>
		<article v-else-if="delivered === 'success'" class="sf--message sf--message__success">
			<strong>Получилось!</strong>
			<p>Теперь вы будете получать уведомления об обновлениях консультационного центрана свой почтовый ящик.</p>
		</article>
		<article v-else-if="delivered === 'fail'" class="sf--message sf--message__fail">
			<strong>Не получилось...</strong>
			<p>Что-то пошло не так, и мы уже работаем над этим. Попробуйте еще раз через какое-то время.</p>
		</article>
	</form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	data() {
		return {
			user_data: {
				email: null,
				subscription_type: 'all'
			}
		}
	},
	props: {
		type: {
			type: String,
			default: 'popup'
		},
		lastUpdate: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters(['getStatus']),
		delivered() {
			let status = null
			if(this.getStatus != null) {
				switch (this.getStatus) {
					case 200:
						status = 'success'
						break;
					case 409:
						status = 'conflict'
						break;
					default:
						status = 'fail'
						break;
				}
				return status
			} else {
				status = 'idle'
				return status
			}
		}
	},
	methods: {
		...mapActions(['subscribeUser', 'cleanStatusCode']),
		sendEmail() {
			this.subscribeUser(this.user_data)
		},
		closeMe() {
			this.$emit('closeForm')
		}
	},
	beforeDestroy() {
		this.cleanStatusCode()
	}
}
</script>
