export default {
	state: {
		records: [],
		record: {},
		fields: [],
	},
	mutations: {
		storeRecords(state, recoreds) {
			state.records = recoreds
		},
		storeRecord(state, record) {
			state.record = record
		},
		storeFields(state, fields) {
			state.fields = fields
		}
	},
	actions: {
		async fetchFields({commit}) {
			const url = process.env.VUE_APP_API_URL + 'orgreg/fields/'
			await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				},
			})
			.then(res => res.json())
			.then(data => commit('storeFields', data))
			.catch(err => {
				console.error(err)
			})
		},

		async fetchRecord({commit}, id) {
			const url = process.env.VUE_APP_API_URL + 'orgreg/' + id
			await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				},
			})
			.then(res => res.json())
			.then(data => commit('storeRecord', data))
			.catch(err => {
				console.error(err)
			})
		},

		async fetchRecords({commit}) {
			const url = process.env.VUE_APP_API_URL + 'orgreg/'
			await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				},
			})
			.then(res => res.json())
			.then(data => commit('storeRecords', data))
			.catch(err => {
				console.error(err)
			})
		},

		async createRecord({commit}, formData) {
			const url = process.env.VUE_APP_API_URL + 'orgreg/'
			await fetch(url, {
				method: 'POST',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => true)
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async updateRecord({commit}, formData) {
			const url = process.env.VUE_APP_API_URL + 'orgreg/'
			await fetch(url, {
				method: 'PUT',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => true)
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},
	},

	getters: {
		getRecords(state) {
			return state.records
		},
		getRecord(state) {
			return state.record
		},
		getFields(state) {
			return state.fields
		}
	}
}
