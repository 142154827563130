import AuthStatus from '@/helpers/auth.js'
export default {
	state: {
		login_status_code: null,
		auth_status: false,
	},
	mutations: {
		storeLoginStatusCode(state, data) {
			state.login_status_code = data
		},
		storeAuthStatus(state) {
			state.auth_status = AuthStatus.isAuthenticated()
		},
	},
	actions: {
		cleanLoginStatusCode({commit}) {
			commit('storeLoginStatusCode', null)
		},

		checkAuthStatus({commit}) {
			commit('storeAuthStatus')
		},

		async login({commit}, user_data) {
			const url = process.env.VUE_APP_API_URL + 'auth/login/'
			await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(user_data)
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeLoginStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeLoginStatusCode', res.status)
					return 409
				} else if(res.status === 403) {
					commit('storeLoginStatusCode', res.status)
					return 403
				} else if(res.status !== 200) {
					commit('storeLoginStatusCode', res.status)
					return false
				}
			})
			.then(data => {
				AuthStatus.storeAuthentication(data)
				commit('storeAuthStatus')
			})
			.catch(err => {
					commit('storeStatusCode', 500)
					console.error(err)
					return false
			})
		},

		async logout({commit}) {
			const url = process.env.VUE_APP_API_URL + 'auth/logout/'
			await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
			})
			.then(res => { return res.json() })
			.then(() => {
				AuthStatus.removeAuthentication()
				commit('storeAuthStatus')
			})
			.catch(error => console.error(error))
		}
	},
	getters: {
		getLoginStatusCode(state) {
			return state.login_status_code
		},
		getAuthStatus(state) {
			return state.auth_status
		},
	}
}
