export default {
	state: {
		materials: [],
		material: {},
		rlas: [],
		rla: {},
		rla_types: [],
		business_types: [],
		materials_types: [],
	},
	mutations: {
		storeMaterials(state, materials_data) {
			state.materials = materials_data
		},
		storeMaterialsTypes(state, materials_data) {
			state.materials_types = materials_data
		},
		storeMaterial(state, material) {
			state.material = material
		},
		storeRlas(state, data) {
			state.rlas = data
		},
		storeRla(state, data) {
			state.rla = data
		},
		storeRlaTypes(state, data) {
			state.rla_types = data
		},
		storeBusinessTypes(state, data) {
			state.business_types = data
		},
	},
	actions: {
		async fetchAllMaterials({commit}) {
			const url = process.env.VUE_APP_API_URL + 'materials/'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeMaterials', data)
			})
			.catch(err => console.error(err))
		},

		async fetchUnapproovedMaterials({commit}) {
			const url = process.env.VUE_APP_API_URL + 'materials/admin'
			await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				}
			})
			.then(res => {return res.json()})
			.then(data => {
				commit('storeMaterials', data)
			})
			.catch(err => console.error(err))
		},

		async createMaterial({commit}, formData) {
			const url = process.env.VUE_APP_API_URL + 'materials/new/'
			await fetch(url, {
				method: 'POST',
				body: formData
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => {return true})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async updateMaterial({commit}, formData) {
			console.log('hello')
			const url = process.env.VUE_APP_API_URL + 'materials/' + formData.get('id')
			await fetch(url, {
				method: 'PUT',
				headers: { 'Authorization': 'Token ' + window.$cookies.get('Token') },
				body: formData
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => {return true})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async createRla({commit}, formData) {
			const url = process.env.VUE_APP_API_URL + 'rla/new/'
			await fetch(url, {
				method: 'POST',
				body: formData
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => {
				return true
			})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async updateRla({commit}, formData) {
			const url = process.env.VUE_APP_API_URL + 'rla/' + formData.get('id')
			await fetch(url, {
				method: 'PUT',
				headers: { 'Authorization': 'Token ' + window.$cookies.get('Token') },
				body: formData
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => {
				return true
			})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async fetchMaterialsTypes({commit}) {
			const url = process.env.VUE_APP_API_URL + 'materials/types/'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeMaterialsTypes', data)
			})
			.catch(err => console.error(err))
		},

		async fetchAllRlas({commit}) {
			const url = process.env.VUE_APP_API_URL + 'rla/'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeRlas', data)
			})
			.catch(err => console.error(err))
		},

		async fetchRla({commit}, id) {
			const url = process.env.VUE_APP_API_URL + 'rla/' + id
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeRla', data)
			})
			.catch(err => console.error(err))
		},

		async fetchUnapproovedRlas({commit}) {
			const url = process.env.VUE_APP_API_URL + 'rla/admin'
			await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				}
			})
			.then(res => {return res.json()})
			.then(data => {
				commit('storeRlas', data)
			})
			.catch(err => console.error(err))
		},

		async fetchRlaTypes({commit}) {
			const url = process.env.VUE_APP_API_URL + 'rla/types'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeRlaTypes', data)
			})
			.catch(err => console.error(err))
		},

		async fetchAllBusinessTypes({commit}) {
			const url = process.env.VUE_APP_API_URL + 'service/business_types_and_materials/'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeBusinessTypes', data)
			})
			.catch(err => console.error(err))
		},

		async fetchMaterial({commit}, id) {
			const url = process.env.VUE_APP_API_URL + 'materials/' + id
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeMaterial', data)
			})
			.catch(err => console.error(err))
		}
	},
	getters: {
		getMaterials(state) {
			return state.materials
		},
		getMaterialsTypes(state) {
			return state.materials_types
		},
		getMaterial(state) {
			return state.material
		},
		getRlas(state) {
			return state.rlas
		},
		getRla(state) {
			return state.rla
		},
		getRlaTypes(state) {
			return state.rla_types
		},
		getBusinessTypes(state) {
			return state.business_types
		},
		getMaterialsWithRlas(state) {
			return state.materials.filter((material) => material.regulatory_legal_acts.length > 0 )
		},
		getMaterialsFormatted(state) {
			return state.materials.map((material) => {
				return {
					id: material.id,
					title: material.title,
				}
			})
		},
		getRlasFormatted(state) {
			return state.rlas.map((rla) => {
				return {
					id: rla.id,
					title: rla.title,
				}
			})
		},
		getBusinessTypesFormatted(state) {
			return state.business_types.map((bt) => {
				return {
					id: bt.id,
					title: bt.type,
				}
			})
		},
	}
}
