export default {
	state: {
		questions: [],
		question_types: [],
		question: {},
	},
	mutations: {
		storeQuestions(state, questions_data) {
			state.questions = questions_data
		},
		storeQuestion(state, question) {
			state.question = question
		},
		storeQuestionTypes(state, types) {
			state.question_types = types
		}
	},
	actions: {
		async fetchAllQuestions({commit}) {
			const url = process.env.VUE_APP_API_URL + 'qanda/'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeQuestions', data)
			})
			.catch(err => console.error(err))
		},

		async fetchUnapproovedQuestions({commit}) {
			const url = process.env.VUE_APP_API_URL + 'qanda/admin'
			await fetch(url, {
				method: 'GET',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				}
			})
			.then(res => {return res.json()})
			.then(data => {
				commit('storeQuestions', data)
			})
			.catch(err => console.error(err))
		},

		async fetchQuestion({commit}, id) {
			const url = process.env.VUE_APP_API_URL + 'qanda/' + id
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeQuestion', data)
			})
			.catch(err => console.error(err))
		},

		async fetchQuestionTypes({commit}) {
			const url = process.env.VUE_APP_API_URL + 'service/questions_and_answers_types/'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeQuestionTypes', data)
			})
			.catch(err => console.error(err))
		},

		async sendQuestion({commit}, {form_data, data_type}) {
			let res
			if (data_type === 'FormData') {
				res = {
					method: 'POST',
					body: form_data,
				}
			} else {
				res = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(form_data),
				}
			}
			const url = process.env.VUE_APP_API_URL + 'qanda/new/'
			await fetch(url, res)
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => {return true})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async createQA({commit}, data) {
			const url = process.env.VUE_APP_API_URL + 'qanda/'
			await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => {
				return true
			})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async updateQA({commit}, data) {
			const url = process.env.VUE_APP_API_URL + 'qanda/' + data.id
			await fetch(url, {
				method: 'PUT',
				headers: {
					'Authorization': 'Token ' + window.$cookies.get('Token'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data),
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(() => {
				return true
			})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async sendSupportQuestion({commit}, form_data) {
			const url = process.env.VUE_APP_API_URL + 'qanda/support/new/'
			await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(form_data),
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(data => {
				console.log(data)
				return true
			})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async storeWebinar({commit}, {form_data, data_type}) {
			let res
			if (data_type === 'FormData') {
				res = {
					method: 'POST',
					body: form_data,
				}
			} else {
				res = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(form_data),
				}
			}
			const url = process.env.VUE_APP_API_URL + 'archive/new/'
			await fetch(url, res)
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(data => {
				console.log(data)
				return true
			})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},

		async updateStoredWebinar({commit}, {form_data, data_type}) {
			let res
			if (data_type === 'FormData') {
				res = {
					method: 'PUT',
					body: form_data,
				}
			} else {
				res = {
					method: 'PUT',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify(form_data),
				}
			}
			const url = process.env.VUE_APP_API_URL + 'archive/' + form_data.get('id')
			await fetch(url, res)
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(data => {
				console.log(data)
				return true
			})
			.catch(err => {
				commit('storeStatusCode', 500)
				console.error(err)
				return false
			})
		},
	},

	getters: {
		getQuestions(state) {
			return state.questions.filter(q => q.is_answered == true)
		},

		getQuestion(state) {
			return state.question
		},

		getQuestionTypes(state) {
			return state.question_types
		}
	}
}
