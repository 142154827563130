import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import mixin from './mixins'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';
import VueHotkey from 'v-hotkey'
import VueCookies from 'vue-cookies'


// IMPORT LAYOUT STYLES
import '@/assets/css/layout.css'
// IMPORT STYLES FILE
import '@/assets/css/style.css'
// IMPORT STYLES FILE
import '@/assets/css/scratchpad.css'
// IMPORT STYLES FILE
import '@/assets/css/animations.css'

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
	componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueHotkey)

// COOKIES
Vue.use(VueCookies)
Vue.$cookies.config('1d','', '', true)

Vue.config.productionTip = false
Vue.mixin(mixin)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

Vue.directive('phone', {
	bind(el) {
		el.oninput = function (e) {
			if (!e.isTrusted) {
				return;
			}

			const x = this.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
			x[1] = '+7'
			this.value = !x[3] ? x[1] + ' (' + x[2] : x[1] + ' (' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '')
			el.dispatchEvent(new Event('input'));
		}
		el.onfocus = function (e) {
			if (!e.isTrusted) {
				return;
			}

			const x = this.value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
			x[1] = '+7'
			this.value = !x[3] ? x[1] + ' (' + x[2] : x[1] + ' (' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '')
			el.dispatchEvent(new Event('input'));
		}
	},
});
