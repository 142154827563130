class AuthStatus {
	isAuthenticated() {
		const hasRole = window.$cookies.get('Role') ? true : false
		const hasToken = window.$cookies.get('Token') ? true : false

		return hasRole && hasToken
	}

	getRole() {
		return window.$cookies.get('Role')
	}

	storeAuthentication(data) {
		window.$cookies.set('Token', data.accessToken)
		window.$cookies.set('Role', data.user.role)

		return this.isAuthenticated()
	}

	removeAuthentication() {
		window.$cookies.remove('Token')
		window.$cookies.remove('Role')

		return this.isAuthenticated()
	}
}

export default new AuthStatus()
