import Vue from 'vue'
import Vuex from 'vuex'
import materials from './modules/materials'
import faq from './modules/faq'
import webinars from './modules/webinars'
import subscriptions from './modules/subscriptions'
import reports from './modules/reports'
import auth from './modules/auth'
import orgreg from './modules/orgreg'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		last_update: new Date(),
		showSingUpForm: false,
		response_status: null,
	},
	mutations: {
		storeLastUpdateDate(state, data) {
			state.last_update = data.last_update
		},
		storeSignUpForm(state, newstate) {
			state.showSingUpForm = newstate
		},
		storeStatusCode(state, status) {
			state.response_status = status
		},
	},
	actions: {
		async fetchLastUpdate({commit}) {
			const url = process.env.VUE_APP_API_URL + 'service/last_update/'
			await fetch(url, { method:'GET', headers: { 'Content-Type': 'application/json' } } )
			.then(res => {return res.json()})
			.then(data => commit('storeLastUpdateDate', data))
			.catch(err => console.error(err))
		},
		toggleSignUpForm({commit, state}) {
			commit('storeSignUpForm', state.showSingUpForm ? false : true )
		},
		cleanStatusCode({commit}) {
			commit('storeStatusCode', null)
		},
	},
	getters: {
		getLastUpdate(state) {
			return state.last_update
		},
		isShowSingUpForm(state) {
			return state.showSingUpForm
		},
		getStaticStoragePath() {
			return process.env.VUE_APP_STATIC_STORAGE
		},
		getStatus(state) {
			console.log(state.response_status)
			return state.response_status
		}
	},
	modules: {
		materials,
		faq,
		webinars,
		subscriptions,
		reports,
		auth,
		orgreg,
	}
})
