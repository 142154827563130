import { mapActions } from "vuex"
var mixin = {
	data() {
		return {
			today: new Date(),
		}
	},
	computed: {
		// GOOGLE CHROME CHECK
		isChrome() {
			if (navigator.appVersion.indexOf("Chrome/") != -1) {
				return true
			} else {
				return false
			}
		},
	},
	methods: {
		...mapActions(['toggleSignUpForm']),
		getDateForInput(date) {
			const new_date = new Date(Date.parse(date))
			return new_date.getFullYear() + '-' +
			((new_date.getMonth() + 1 < 10) ? '0' + (new_date.getMonth() + 1) : (new_date.getMonth() + 1)) + '-' +
			((new_date.getDate() < 10) ? '0' + (new_date.getDate()) : (new_date.getDate()))
		},
		normalizeTime(date) {
			const new_date = new Date(Date.parse(date))
			return new_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
		},
		normalizeDate(date) {
			const new_date = new Date(Date.parse(date))
			return new_date.toLocaleDateString()
		},
		normalizeDateTime(date) {
			const new_date = new Date(Date.parse(date))
			return new_date.toLocaleDateString() + ' ' + new_date.toLocaleTimeString()
		},
		isEventExpired(event_date, today) {
			const time_shift = -3
			const ed = new Date(Date.parse(event_date)).getTime()
			const td_with_shift = this.addHoursToDate(today, time_shift).getTime()
			if (ed < td_with_shift) {return true} else {return false}
		},
		isEventPlanned(event_date, today) {
			const edt = new Date(Date.parse(event_date))
			const tdt = new Date(Date.parse(today))
			if (edt > tdt) {return true} else {return false}

		},
		isEventToday(event_date, today) {
			const ed = new Date(Date.parse(event_date)).toLocaleDateString()
			const td = new Date(Date.parse(today)).toLocaleDateString()
			if (ed == td) {return true} else {return false}
		},
		isEventNow(event_date, today) {
			const ed = new Date(Date.parse(event_date)).toLocaleDateString()
			const td = new Date(Date.parse(today)).toLocaleDateString()
			const edt = new Date(Date.parse(event_date)).toLocaleTimeString()
			const tdt = new Date(Date.parse(today)).toLocaleTimeString()
			if (ed == td && edt == tdt) {return true} else {return false}
		},
		addHoursToDate(date, h) {
			const local_date = new Date(date)
			Date.prototype.addHours = function(h) {
				this.setTime(this.getTime() + (h*60*60*1000));
				return this;
			}
			return local_date.addHours(h)
		},
		dateIsInRange(event_date, range) {
			const { start, end } = range
			const start_date = new Date(start)
			const end_date = new Date(end)
			const date = new Date(event_date)
			if(start_date != end_date) {
				if(start_date <= date && end_date >= date) {return true} else {return false}
			} else {
				if(start_date == date) {return true} else {return false}
			}
		},
		jsonToFormDataHelper(data) {
			const formData = new FormData()
			for (const key in data) {
				if (typeof data[key] !== 'object') {
					formData.append(key, data[key])
				} else if (data[key] != null) {
					for (const [i, obj] of data[key].entries()) {
						for (const j in obj) {
							formData.append(`${key}[${i}][${j}]`, obj[j])
						}
					}
				}
			}
			return formData
		},
	},

}

export default mixin
