export default {
	state: {
		webinars: [],
		webinar: {},
		webinar_files: [],
		fake_webinar: {
			started: new Date(),
			title: 'Тема уточняется',
			organization: '',
			speakers: 'Докладчики уточняются',
			materials_link: '/',
			record_link: '/',
		},
		// WEBINAR RU
		on_live_webinar: false,
		stopped_webinars: [],
		active_webinars: [],
		all_webinars: [],
		// DATABASE
		register_result: {}
	},
	mutations: {
		storeWebinars(state, webinars_data) {
			state.webinars = webinars_data
		},
		storeWebinar(state, webinar) {
			state.webinar = webinar
		},
		cleanUpWebinar(state) {
			state.webinar = {}
		},
		// WEBINAR RU
		storeWebinarFromWebinarRu(state, webinar) {
			let lectors = []
			if(webinar.lectors && webinar.lectors.length > 0) {
				lectors = Array.from(webinar.lectors).map((lector) => {
					return {
						name: lector.name,
						surname: lector.secondName,
						organization: lector.description
					}
				})
				state.webinar = {
					id: webinar.id,
					started: webinar.startsAt,
					title: webinar.name,
					description: webinar.description,
					speakers: lectors,
					organization: null,
					record_link: "https://webinars.webinar.ru/rosdorspk/"+ webinar.id +"/record-new/" + webinar.id,
					is_planned: webinar.status == 'ACTIVE' ? true : false,
					is_ended: webinar.status == 'STOP' ? true : false,
					is_onair: webinar.status == 'START' ? true : false
				}
			} else {
				state.webinar = {
					id: webinar.id,
					started: webinar.startsAt,
					title: webinar.name,
					description: webinar.description,
					speakers: webinar.lector ? webinar.lector : 'Спикеры не определены',
					organization: webinar.lectors && webinar.lectors.length > 0 ? webinar.lectors[0].description : 'Организация не указана',
					record_link: "https://webinars.webinar.ru/rosdorspk/"+ webinar.id +"/record-new/" + webinar.id,
					is_planned: webinar.status == 'ACTIVE' ? true : false,
					is_ended: webinar.status == 'STOP' ? true : false,
					is_onair: webinar.status == 'START' ? true : false
				}
			}

		},
		storeActiveWebinars(state, webinars) {
			state.active_webinars = Array.from(webinars).map((webinar) => {
				return {
					id: webinar.eventSessions ? webinar.eventSessions[0].id : null,
					started: webinar.startsAt,
					title: webinar.name,
					description: webinar.description,
					speakers: webinar.lector ? webinar.lector : 'Спикеры не определены',
					organization: webinar.lectors && webinar.lectors.length > 0 ? webinar.lectors[0].description : 'Организация не указана',
					is_planned: webinar.status == 'ACTIVE' ? true : false,
					is_ended: webinar.status == 'STOP' ? true : false,
					is_onair: webinar.status == 'START' ? true : false
				}
			})
		},
		storeStartedWebinars(state, webinars) {
			state.on_live_webinar = webinars.map((webinar) => {
				return {
					id: webinar.eventSessions[0].id,
					started: webinar.startsAt,
					title: webinar.name,
					description: webinar.description,
					speakers: webinar.lector ? webinar.lector : 'Спикеры не определены',
					organization: webinar.lectors && webinar.lectors.length > 0 ? webinar.lectors[0].description : 'Организация не указана',
					is_onair: webinar.status == 'START' ? true : false
				}
			})[0]
		},
		storeStoppedWebinars(state, webinars) {
			state.stopped_webinars = Array.from(webinars).map((webinar) => {
				return {
					id: webinar.eventSessions[0].id,
					started: webinar.startsAt,
					title: webinar.name,
					description: webinar.description,
					record_link: "https://webinars.webinar.ru/rosdorspk/"+ webinar.id +"/record-new/" + webinar.id,
					speakers: webinar.lector ? webinar.lector : 'Спикеры не определены',
					organization: webinar.lectors && webinar.lectors.length > 0 ? webinar.lectors[0].description : 'Организация не указана',
					is_planned: webinar.status == 'ACTIVE' ? true : false,
					is_ended: webinar.status == 'STOP' ? true : false,
					is_onair: webinar.status == 'START' ? true : false
				}
			})
		},
		storeAllWebinars(state, webinars) {
			state.all_webinars = webinars.map((webinar) => {
				if(webinar.eventSessions.length != 0) {
					return {
						id: webinar.eventSessions[0] ? webinar.eventSessions[0].id : null,
						started: webinar.startsAt,
						title: webinar.name,
						description: webinar.description,
						record_link: "https://webinars.webinar.ru/rosdorspk/"+ webinar.id +"/record-new/" + webinar.id,
						speakers: webinar.lector ? webinar.lector : 'Спикеры не определены',
						organization: webinar.lectors && webinar.lectors.length > 0 ? webinar.lectors[0].description : 'Организация не указана',
						is_planned: webinar.status == 'ACTIVE' ? true : false,
						is_ended: webinar.status == 'STOP' ? true : false,
						is_onair: webinar.status == 'START' ? true : false
					}
				} else {
					return false
				}
			})
		},
		storeWebinarFiles(state, files) {
			state.webinar_files = files
		},

		storeRegisterResult(state, result) {
			state.register_result = result
		}
	},
	actions: {
		async fetchAllWebinars({commit}) {
			const url = process.env.VUE_APP_API_URL + 'webinars/'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeWebinars', data)
			})
			.catch(err => console.error(err))
		},

		async fetchArchiveWebinars({commit}) {
			const url = process.env.VUE_APP_API_URL + 'archive/'
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeWebinars', data)
			})
			.catch(err => console.error(err))
		},

		async fetchWebinar({commit, dispatch}, id) {
			const url = process.env.VUE_APP_API_URL + 'webinars/' + id
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				if(data != null) {
					commit('storeWebinar', data)
				} else {
					dispatch('fetchWebinarFromWebinarRu', id)
				}
			})
			.catch(err => console.error(err))
		},

		async fetchArchiveWebinar({commit}, id) {
			const url = process.env.VUE_APP_API_URL + 'archive/' + id
			await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } })
			.then(res => {return res.json()})
			.then(data => {
				commit('storeWebinar', data)
			})
			.catch(err => console.error(err))
		},

		// WEBINAR.RU API METHODS
		async fetchActiveWebinars({commit}) {
			const url = process.env.VUE_APP_API_URL + 'proxy/webinars/active/'

			await fetch(url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res => {return res.json()})
			.then(data => commit('storeActiveWebinars', data))
			.catch(err => console.error(err))
		},

		async fetchStartedWebinars({commit}) {
			const url = process.env.VUE_APP_API_URL + 'proxy/webinars/started/'

			await fetch(url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res => {return res.json()})
			.then(data => commit('storeStartedWebinars', data))
			.catch(err => console.error(err))
		},

		async fetchStoppedWebinars({commit}) {
			const url = process.env.VUE_APP_API_URL + 'proxy/webinars/stopped/'

			await fetch(url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res => {return res.json()})
			.then(data => commit('storeStoppedWebinars', data))
			.catch(err => console.error(err))
		},

		async fetchAllWebinarsFromWebinarRu({commit}) {
			const url = process.env.VUE_APP_API_URL + 'proxy/webinars/'

			await fetch(url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res => {return res.json()})
			.then(data => commit('storeAllWebinars', data))
			.catch(err => console.error(err))
		},

		async fetchWebinarFromWebinarRu({commit}, id) {
			const url = process.env.VUE_APP_API_URL + 'proxy/webinars/' + id
			await fetch(url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res => {return res.json()})
			.then(data => {
				commit('storeWebinarFromWebinarRu', data)
				return true
			})
			.catch(err => console.error(err))
		},

		// GET FILES LIST
		async fetchWebinarFiles({commit}, id) {
			const url = process.env.VUE_APP_API_URL + 'proxy/webinars/' + id + '/files/'
			await fetch (url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res => {return res.json()})
			.then(data => {
				commit('storeWebinarFiles', data)
				return true
			})
			.catch(err => console.error(err))
		},

		// SIGN USER TO WEBINAR ON WEBINAR RU
		async signUpToWebinar({commit}, data) {
			const url = process.env.VUE_APP_API_URL + 'proxy/webinars/' + data.event_id + '/register'

			await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(data)
			})
			.then(res => {return res.json()})
			.then(data => {
				commit('storeRegisterResult', data)
				return true
			})
			.catch(err => console.error(err))
		}

	},

	getters: {
		// DATABASE GETTERS
		getFakeWebinar(state) {
			return state.fake_webinar
		},

		getWebinars(state) {
			return state.webinars
		},

		getWebinar(state) {
			return state.webinar
		},

		// WEBINAR RU GETTERS
		getActiveWebinars(state) {
			return state.all_webinars.filter((webinar => webinar.is_planned))
		},
		getStartedWebinar(state) {
			return state.on_live_webinar
		},
		getStoppedWebinars(state) {
			return state.all_webinars.filter((webinar => webinar.is_ended)).sort((a, b) => new Date(b.started) - new Date(a.started))
		},
		getRegisterResult(state) {
			return state.register_result
		},
		getAllWebinars(state) {
			return state.all_webinars
		},
		getAbsolubleyAllWebinarsForCalendar(state) {
			let all_webinars = state.webinars
			if(state.all_webinars.length != 0) all_webinars = all_webinars.concat(state.all_webinars)
			const calendar_webinars = all_webinars.map((webinar) => {
				return {
					key: Number(webinar.id),
					customData: {
						id: webinar.id,
						title: webinar.title,
						date: webinar.started
					},
					dates: new Date(webinar.started),
				}
			})
			return calendar_webinars
		},
		getAbsolubleyAllWebinars(state) {
			let all_webinars = state.webinars
			if(state.all_webinars.length != 0) all_webinars = all_webinars.concat(state.all_webinars)
			return all_webinars
		},

		getWebinarFiles(state) {
			return state.webinar_files
		}
	}
}
