export default {
	state: {
	},
	mutations: {
	},
	actions: {
		async subscribeUser({commit}, user_data) {
			const url = process.env.VUE_APP_API_URL + 'subscriptions/'
			await fetch(url, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(user_data)
			})
			.then(res => {
				if(res.status === 200) {
					commit('storeStatusCode', res.status)
					return res.json()
				} else if(res.status === 409) {
					commit('storeStatusCode', res.status)
					return 409
				} else if(res.status !== 200) {
					commit('storeStatusCode', res.status)
					return false
				}
			})
			.then(data => {
				console.log(data)
				return true
			})
			.catch(err => {
					commit('storeStatusCode', 500)
					console.error(err)
					return false
			})
		}
	},
	getters: {
	}
}
