export default {
	state: {
		// Webinar data

		// Webinar Stats by Regions

		// Webinar Stats by Visitors
		visitiors_stats: [],
		// Webinar Questions
		report_questions: [],
		// All Subjects
		subjects: [],
		// Stored RF subjects
		rf_subjects: [
			"Алтайский край",
			"Амурская область",
			"Архангельская область",
			"Астраханская область",
			"Белгородская область",
			"Брянская область",
			"Владимирская область",
			"Волгоградская область",
			"Вологодская область",
			"Воронежская область",
			"г. Москва",
			"г. Санкт-Петербург",
			"г. Севастополь",
			"Еврейская автономная область",
			"Забайкальский край",
			"Ивановская область",
			"Иркутская область",
			"Кабардино-Балкарская Республика",
			"Калининградская область",
			"Калужская область",
			"Камчатский край",
			"Карачаево-Черкесская Республика",
			"Кемеровская область - Кузбасс",
			"Кировская область",
			"Костромская область",
			"Краснодарский край",
			"Красноярский край",
			"Курганская область",
			"Курская область",
			"Ленинградская область",
			"Липецкая область",
			"Магаданская область",
			"Московская область",
			"Мурманская область",
			"Ненецкий автономный округ",
			"Нижегородская область",
			"Новгородская область",
			"Новосибирская область",
			"Омская область",
			"Оренбургская область",
			"Орловская область",
			"Пензенская область",
			"Пермский край",
			"Приморский край",
			"Псковская область",
			"Республика Адыгея",
			"Республика Алтай",
			"Республика Башкортостан",
			"Республика Бурятия",
			"Республика Дагестан",
			"Республика Ингушетия",
			"Республика Калмыкия",
			"Республика Карелия",
			"Республика Коми",
			"Республика Крым",
			"Республика Марий Эл",
			"Республика Мордовия",
			"Республика Саха Якутия",
			"Республика Северная Осетия - Алания",
			"Республика Татарстан",
			"Республика Тыва",
			"Республика Хакасия",
			"Ростовская область",
			"Рязанская область",
			"Самарская область",
			"Саратовская область",
			"Сахалинская область",
			"Свердловская область",
			"Смоленская область",
			"Ставропольский край",
			"Тамбовская область",
			"Тверская область",
			"Томская область",
			"Тульская область",
			"Тюменская область",
			"Удмуртская Республика",
			"Ульяновская область",
			"Хабаровский край",
			"Ханты-Мансийский автономный округ - Югра",
			"Челябинская область",
			"Чеченская Республика",
			"Чувашская Республика",
			"Чукотский автономный округ",
			"Ямало-Ненецкий автономный округ",
			"Ярославская область",
			"Донецкая Народная Республика (ДНР)",
			"Луганская Народная Республика (ЛНР)",
			"Запорожская область",
			"Херсонская область"
			
		]
	},
	mutations: {
		// Store Webinar Data - Title, Date, Speaker

		// Store Webinar Stats by Regions - code, subject(name), Requests, Visits, % of Visitis

		// Store Webinar Stats by Visitors - subject-name, Name+Surname+Middlename, Organization, Position, Visited (bool)
		storeParticipations(state, participations) {
			let subject_list = []
			state.visitiors_stats = participations.map((visitor) => {
				const organization = visitor.additionalFieldValues.filter(value => {
					if(value.value != undefined || value.value != null) {
						return value.label == 'Компания' ? true : false
					} else {
						return false
					}
				})
				const subject = visitor.additionalFieldValues.filter((value) => {
					if(value.value != undefined || value.value != null) {
						return (value.label == 'Субъект РФ' || value.label == 'Город') ? true : false
					} else {
						return false
					}
				})
				subject_list.push(subject[0] ? subject[0].value : null)
				const middlename = visitor.additionalFieldValues.filter((value) => {
					if(value.value != undefined || value.value != null) {
						return value.label == 'Отчество' ? true : false
					} else {
						return false
					}
				})
				const position = visitor.additionalFieldValues.filter((value) => {
					if(value.value != undefined || value.value != null) {
						return value.label == 'Должность' ? true : false
					} else {
						return false
					}
				})
				return {
					subject: subject[0] ? subject[0].value : 'Неизвестно',
					fullName: visitor.secondName + ' ' + visitor.name + ' ' + (middlename[0] ? middlename[0].value : '-'),
					organization: organization[0] ? organization[0].value : '-',
					position: position[0] ? position[0].value : '-',
					visited: visitor.visited ? 'да' : 'нет'
				}
			})
			.filter(visitor => visitor.subject !== 'Неизвестно')
			.filter(visitor => state.rf_subjects.includes(visitor.subject))
			state.subjects = subject_list
		},
		// Store Webinar Questions
		storeReportQuestions(state, report_questions) {
			state.report_questions = report_questions
		}
	},
	actions: {
		// Get Eventsession participations
		async fetchEventSessionParticipations({commit}, eventId) {
			const url = process.env.VUE_APP_API_URL + 'proxy/webinars/'+ eventId + '/participations/'
			await fetch(url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res => {return res.json()})
			.then(data => commit('storeParticipations', data))
			.catch(err => console.error(err))
		},

		// Get Eventsession Stats

		// Get Event Info

		// Get Webinar Questions
		async fetchWebinarQuestions({commit}, eventId) {
			const url =process.env.VUE_APP_API_URL + 'proxy/webinars/'+ eventId + '/questions/'
			await fetch(url, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' }
			})
			.then(res => {return res.json()})
			.then(data => commit('storeReportQuestions', data))
			.catch(err => console.error(err))
		}
	},

	getters: {
		// Get Webinar Data - Title, Date, Speaker

		// Get Webinar Stats by Regions - code, subject(name), Requests, Visits, % of Visitis

		// Get Webinar Stats by Visitors - subject-name, Name+Surname+Middlename, Organization, Position, Visited (bool)
		getVisitorsStats(state) {
			return state.visitiors_stats
		},

		// Get Webinar Questions
		getWebinarQuestionsReport(state) {
			return state.report_questions
		},
		// Get RF subjects
		getRfSubjects(state) {
			return state.rf_subjects.sort((a, b) => a.localeCompare(b))
		}
	}
}
