import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store';
import Dev from '../views/Dev.vue'
import AuthStatus from '@/helpers/auth.js'

Vue.use(VueRouter)

const routes = [
	{
		path: '/dev',
		name: 'Dev',
		meta: { auth: true },
		component: Dev
	},
	{
		path: '/adminlogin',
		name: 'Login',
		component: () => import('../views/LoginPage.vue')
	},
	{
		path: '/',
		name: 'Main',
		component: () => import('../views/Main.vue')
	},
	{
		path: '/zapisatsya-na-vebinar',
		name: 'SubscribeToWebinar2',
		component: () => import('../views/webinars/SubscribeToWebinar.vue')
	},
	{
		path: '/zapisatsya-na-vebinar/:id',
		name: 'SubscribeToWebinar',
		props: true,
		component: () => import('../views/webinars/SubscribeToWebinar.vue')
	},
	{
		path: '/vebinary',
		name: 'Webinars',
		component: () => import('../views/Webinars.vue')
	},
	{
		path: '/vebinary/arkhiv',
		name: 'WebinarsArchive',
		component: () => import('../views/webinars/WebinarsArchive.vue')
	},
	{
		path: '/vebinary/arkhiv/:page',
		name: 'WebinarsArchivePage',
		props: true,
		component: () => import('../views/webinars/WebinarsArchive.vue')
	},
	{
		path: '/vebinary/:id',
		name: 'Webinar',
		props: true,
		component: () => import('../views/webinars/Webinar.vue')
	},
	{
		path: '/baza-znaniy',
		name: 'KnowledgeBase',
		component: () => import('../views/KnowledgeBase.vue'),
	},
	{
		path: '/baza-znaniy/materialy/',
		name: 'Materials',
		meta: { savedPosition: true },
		component: () => import('../views/kb/Materials.vue')
	},
	{
		path: '/baza-znaniy/materialy/:page',
		name: 'MaterialsPage',
		props: true,
		component: () => import('../views/kb/Materials.vue')
	},
	{
		path: '/baza-znaniy/materialy/:business_type',
		name: 'MaterialsByBusinessType',
		props: true,
		component: () => import('../views/kb/Materials.vue')
	},
	{
		path: '/baza-znaniy/materialy/:business_type/:page',
		name: 'MaterialsByBusinessTypePage',
		props: true,
		// meta: { savedPosition: true },
		component: () => import('../views/kb/Materials.vue')
	},
	{
		path: '/baza-znaniy/normativno-pravovye-akty',
		name: 'Rlas',
		// meta: { savedPosition: true },
		component: () => import('../views/kb/Rlas.vue')
	},
	{
		path: '/baza-znaniy/normativno-pravovye-akty/:page',
		name: 'RlasPage',
		props: true,
		component: () => import('../views/kb/Rlas.vue')
	},
	{
		path: '/baza-znaniy/normativno-pravovye-akty/:id',
		name: 'Rla',
		props: true,
		// meta: { savedPosition: true },
		component: () => import('../views/kb/rla/Rla.vue')
	},
	{
		path: '/baza-znaniy/vidy-deyatelnosti',
		name: 'BusinessTypes',
		// meta: { savedPosition: true },
		component: () => import('../views/kb/BusinessTypes.vue')
	},
	{
		path: '/vopros-otvet',
		name: 'Faq',
		component: () => import('../views/Faq.vue')
	},
	{
		path: '/vopros-otvet/voprosy-ehkspertam-dorozhnogo-khozyajstva',
		name: 'FaqExperts',
		component: () => import('../views/faq/Experts.vue')
	},
	{
		path: '/vopros-otvet/vebinary',
		name: 'FaqWebinras',
		component: () => import('../views/faq/Webinars.vue')
	},
	{
		path: '/vopros-otvet/baza-znanij',
		name: 'FaqKnowledgeBase',
		component: () => import('../views/faq/KnowledgeBase.vue')
	},
	{
		path: '/vopros-otvet/zadat-vopros-ehkspertu',
		name: 'AskQuestionExpert',
		meta: {
			form_type: 'expert',
		},
		component: () => import('../views/faq/Question.vue')
	},
	{
		path: '/vopros-otvet/zadat-vopros-tekhnicheskoj-podderzhke',
		name: 'AskQuestionSupport',
		meta: {
			form_type: 'support',
		},
		component: () => import('../views/faq/Question.vue')
	},
	{
		path: '/vopros-otvet/:question',
		name: 'Question',
		component: () => import('../views/faq/QuestionAndAnswer.vue')
	},
	// Reports
	{
		path: '/admin',
		name: 'AdminPage',
		meta: {
			auth: true,
			role: 1,
		},
		component: () => import('../views/admin/AdminPage.vue')
	},
	{
		path: '/pastwebinars',
		name: 'PastWebinars',
		meta: {
			auth: true,
			role: 1,
		},
		props: true,
		component: () => import('../views/admin/PastWebinars.vue')
	},
	{
		path: '/pastwebinars/:id',
		name: 'StoreArchiveWebinar',
		meta: {
			auth: true,
			role: 1,
		},
		props: true,
		component: () => import('../views/admin/StoreArchiveWebinar.vue')
	},
	{
		path: '/archivewebinars',
		name: 'ArchiveWebinars',
		meta: {
			auth: true,
			role: 1,
		},
		props: true,
		component: () => import('../views/admin/WebinarsInArchive.vue')
	},
	{
		path: '/archivewebinars/:id',
		name: 'EditArchiveWebinar',
		meta: {
			auth: true,
			role: 1,
		},
		props: true,
		component: () => import('../views/admin/EditArchiveWebinar.vue')
	},
	{
		path: '/reports',
		name: 'Reports',
		meta: {
			auth: true,
			role: 1,
		},
		props: true,
		component: () => import('../views/admin/ReportsPage.vue')
	},
	{
		path: '/reports/:id',
		name: 'ReportPage',
		meta: {
			auth: true,
			role: 1,
		},
		props: true,
		component: () => import('../views/admin/WebinarReport.vue')
	},
	{
		path: '/admin/materials',
		name: 'AdminMaterials',
		meta: {
			auth: true,
			role: 1,
		},
		component: () => import('../views/admin/materials/MaterialsTasks.vue')
	},
	{
		path: '/admin/materials/:id:type',
		name: 'AdminMaterialPage',
		meta: {
			auth: true,
			role: 1,
		},
		props: true,
		component: () => import('../views/admin/materials/MaterialData.vue')
	},
	{
		path: '/new-material',
		name: 'NewMaterial',
		meta: {
			auth: false,
		},
		component: () => import('../views/admin/materials/NewMaterial.vue')
	},
	{
		path: '/organization_registry',
		name: 'OrganizationRegistry',
		meta: {
			auth: true,
			role: 2,
		},
		component: () => import('../views/OrganizationRegistry/OrganizationRegistry.vue')
	},
	{
		path: '/organization_registry/list',
		name: 'OrganizationList',
		meta: {
			auth: true,
			role: 2,
		},
		component: () => import('../views/OrganizationRegistry/OrganizationList.vue')
	},
	{
		path: '/organization_registry/list/:id',
		name: 'OrganizationPage',
		props: true,
		meta: {
			auth: true,
			role: 2,
		},
		component: () => import('../views/OrganizationRegistry/OrganizationPage.vue')
	},
	{
		path: '/organization_registry/list/:id/edit',
		name: 'EditOrganization',
		props: true,
		meta: {
			auth: true,
			role: 2,
		},
		component: () => import('../views/OrganizationRegistry/EditOrganization.vue')
	},
	{
		path: '/organization_registry/new',
		name: 'CreateOrganization',
		meta: {
			auth: true,
			role: 2,
		},
		component: () => import('../views/OrganizationRegistry/CreateOrganization.vue')
	},

	// 404
	{
		path: '*',
		component: () => import('../views/NotFoundPage.vue')
	}
]

const scrollBehavior = function (to, from, savedPosition) {
	if (to.matched.some(m => m.meta.savedPosition)) {
		return savedPosition
	} else {
		const position = {x: 0, y:0}
		return position
	}
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior,
	routes,
})


router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.auth) && !AuthStatus.isAuthenticated()) {
			// You can use store variable here to access globalError or commit mutation
			next({ name: 'Login' })
		} else {
			if(to.matched.some(record => record.meta.role)) {
				console.log(to.matched[0].meta.role, Number(AuthStatus.getRole()))
				if(to.matched[0].meta.role === Number(AuthStatus.getRole())) {
					next()
				} else {
					next({ name: 'Login' })
				}
			} else {
				next()
			}
	}
})

// const settings = {
// 	primaryFocusTarget: "main a",
// 	smoothScroll: false,
// };

// wrapRouter(router, settings)
export default router
